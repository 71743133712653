import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { IonApp, setupIonicReact } from "@ionic/react";

import HostnameTabs from "./HostnameTabs";
import AppTabs from "./AppTabs";
import { IonReactRouter } from "@ionic/react-router";

import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./theme/variables.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SelectedOriContext } from "./context/SelectedOriContext"; 
import { Amplify, type ResourcesConfig } from 'aws-amplify';
import { defaultStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Authenticator } from "@aws-amplify/ui-react";
import { WebSocketProvider } from './WebSocketContext';

const authConfig: ResourcesConfig['Auth'] = {

  Cognito: {
    userPoolId: 'eu-central-1_3ui4PcA8s',
    userPoolClientId: '3489j27uvv4l9fr1mbjp6baddu',
    identityPoolId: 'eu-central-1:8a85a8eb-2d8c-4bd2-b626-aac03adbbf3d',
  }
};

// ✅ Configure Amplify only ONCE
Amplify.configure({
  Auth: authConfig
});


// ✅ Set storage (required for token caching)
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
setupIonicReact();

const App: React.FC = () => {
  const [selectedOri, setSelectedOri] = useState<string | null>(null);


  useEffect(() => {
    fetchAuthSession()
      .then(session => {
        console.log("✅ Amplify Connected - Session:", session);
      })
      .catch(err => {
        console.error("❌ Amplify connection error:", err);
      });
  }, []);


  return (
    
    <IonApp>
      <WebSocketProvider>
      <ToastContainer />
      <Authenticator.Provider>
      <IonReactRouter>
        <SelectedOriContext.Provider value={{ selectedOri, setSelectedOri }}> 
          <Switch>
            <Route path="/hostname" component={HostnameTabs} />
            <Route path="/">
              {selectedOri ? <AppTabs /> : <Redirect to="/hostname/select-hostname" />}
            </Route>
          </Switch>
        </SelectedOriContext.Provider>
      </IonReactRouter>
      </Authenticator.Provider>
      </WebSocketProvider>
    </IonApp>
  );
};

export default App;
