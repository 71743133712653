import { IonButtons, IonContent, IonHeader, IonButton, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonImg, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonToggle, useIonModal } from '@ionic/react';
import { IonInput, IonList, IonListHeader, IonItem, IonLabel, useIonAlert, IonText  } from '@ionic/react';
import { OverlayEventDetail } from '@ionic/core/components';
import { useState, useEffect } from 'react';
import { sendOriCollectorCommand } from '../data/api';
import './ConfigMain.css';
import ConfigSystem from './ConfigSystem';
import "moment/locale/es";
import { useWebSocketStore } from '../stores/zustang';
import { useGlobalUser } from "../GlobalStoreContext";
import { useIonRouter } from "@ionic/react";

// Other imports...

interface ConfigMessage {
  config: { id: string; value: string; description: string }[];
  system?: {
    network?: { hostname?: string; ip?: string };
    datetime?: string;
    timezone?: string;
  };
  [key: string]: any;
}

const Config: React.FC = () => {  
  const config = useWebSocketStore((state) => state.config) as ConfigMessage;
  const resetStore = useWebSocketStore((state) => state.resetStore);
  const { userId, accessToken, selectedOri } = useGlobalUser();

  const getConfigValue = (id: string): string | undefined => {
    if (!Array.isArray(config?.config)) {
      console.warn("❌ config.config is not an array", config?.config);
      return undefined;
    }
  
    return config.config.find((c) => c.id === id)?.value;
  };
  
  const [ori, setOri] = useState("");
  const [robot, setRobot] = useState("");
  const [farm, setFarm] = useState("");
  const [username, setUsername] = useState("");
  const [numsamples, setNumsamples] = useState(0);
  const [detectPositionZero, setDetectPositionZero] = useState(false);
  const [showAlerts, setShowAlerts] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string>("");
  const [ip, setIp] = useState<string>("");
  const [hostname, setHostname] = useState<string>("");

  const [presentAlert] = useIonAlert();
  const numSamplesCollection = Array.from({ length: 90 }, (_, i) => (i + 1).toString());

  const router = useIonRouter();


  // Navigate to hostname selection
  const handleGoToHostnameSelection = () => {
    // Perform any necessary cleanup or state management here
    // For example, you might want to reset some state variables or clear any data
    resetStore(); // Reset the store or any relevant state
    router.push('/hostname/select-hostname', "forward"); // Redirect to the hostname selection page
  };

  const getFormData = () => {
    return {
      ori,
      robot,
      farm,
      username,
      numsamples: String(numsamples), // just to be safe,
      detectpositionzero: detectPositionZero ? 1 : 0,
      showalerts: showAlerts ? 1 : 0,
      email
    };
  };
  
  const onSave = async () => {
    const config = getFormData();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(String(config.email || ""))) {
      presentAlert({
        header: 'Email Invalido',
        message: 'Por favor, introduce un email valido.',
        buttons: ['OK'],
      });
      return;
    }
    sendOriCollectorCommand(userId, selectedOri, "update_config", config, accessToken).then(result => {
      if (result.message) {
        presentAlert({
          header: 'Guardado',
          message: 'Se han guardado los ajustes correctamente.',
          buttons: ['OK'],
        });
      } else {
        presentAlert({
          header: 'Fallo',
          message: 'No se ha podido guardar los ajustes.',
          buttons: ['OK'],
        });
      }
    }).catch(() => {
      presentAlert({
        header: 'Fallo',
        message: 'No se ha podido guardar los ajustes.',
        buttons: ['OK'],
      });
    });
  };

  const [present, dismiss] = useIonModal(ConfigSystem, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    userId,
    accessToken,
    selectedOri
  });

  const handleEmailChange = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setError("");
  };

  const showConfigSystem = () => {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'confirm') {
          // Handle dismiss logic
        }
      },
    });
  };

  useEffect(() => {
    if (!config?.config) return; // 🔥 Check the internal array instead of whole config

    setOri(getConfigValue("ori") || "");
    setRobot(getConfigValue("robot") || "");
    setFarm(getConfigValue("farm") || "");
    setUsername(getConfigValue("username") || "");
    setNumsamples(parseInt(getConfigValue("numsamples") || "0", 10));
    setDetectPositionZero(getConfigValue("detectpositionzero") === "1");
    setShowAlerts(getConfigValue("showalerts") === "1");
    setEmail(getConfigValue("email") || "");
    setIp(config.system?.network?.ip || "");
    setHostname(config.system?.network?.hostname || "");


  }, [ config]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Ajustes</IonTitle>
          <IonButtons collapse={false} slot="end">
            <IonButton onClick={() => onSave()}>Guardar</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent  >
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Ajustes</IonTitle>
          </IonToolbar>
        </IonHeader>        
        
        <IonList inset={true}>
          <IonListHeader>
            <IonLabel>Datos por omisión</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel position="stacked">Farm Name</IonLabel>
            <IonInput  placeholder="" value={farm} onIonChange={(e) => setFarm(e.detail.value ?? "")}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">OriCollector</IonLabel>
            <IonInput  placeholder="" value={ori} onIonChange={(e) => setOri(e.detail.value ?? "")}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Robot</IonLabel>
            <IonInput placeholder="" value={robot} onIonChange={(e) => setRobot(e.detail.value ?? "")}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Usuario</IonLabel>
            <IonInput placeholder="" value={username}  onIonChange={(e) => setUsername(e.detail.value ?? "")}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput
              
              value={email}
              onIonInput={handleEmailChange}
              placeholder="Enter your email"
              type="email"
            />
          </IonItem>
          {error && (
            <IonText color="danger">
              <p>{error}</p>
            </IonText>
          )}
          <IonItem>  
            <IonLabel>Número de muestras</IonLabel>
            <IonSelect onIonChange={(e) => setNumsamples(parseInt((e.detail.value) || "0", 10))} placeholder="" cancelText="Cancelar" value={numsamples.toString()}>
              {numSamplesCollection.map((sample: string) => (
                <IonSelectOption key={sample} value={sample}>{sample}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonList>

        <IonList inset={true}>
          <IonListHeader>
            <IonLabel>Ajustes</IonLabel>
          </IonListHeader>
          <IonItem>
              <IonLabel>Detectar posición cero</IonLabel>
              {detectPositionZero !== undefined && (
              <IonToggle slot="end" onIonChange={(e) => setDetectPositionZero(e.detail.checked)} checked={detectPositionZero}></IonToggle>
              )}
          </IonItem>

          <IonItem detail={true} button  onClick={() => showConfigSystem()}>
              <IonLabel>Ajustes del sistema</IonLabel>
          </IonItem>
          
        </IonList>

        <IonButton
          expand="block"
          color="primary"
          onClick={handleGoToHostnameSelection} // Navigate to hostname selection
        >
          Volver a la selección de Ori-Collector
        </IonButton>

        <IonCard className="card-about">          
          <IonImg src="assets/logo.jpg" alt="Sayca" className="logo-img" />
          <IonCardHeader>
            <IonCardTitle>OriApp</IonCardTitle>
            <IonCardSubtitle>Versión Beta 0.1.2</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            {config && config.system && (
              <div>
                Host: <strong>{hostname}</strong><br />
                IP: <strong>{ip}</strong>
              </div>
            )}
          </IonCardContent>
        </IonCard>
        
      </IonContent>
    </>
  );
};

export default Config;


