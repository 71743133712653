import React, { useEffect, useState } from 'react';
import {IonButtons, IonButton, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonItem, IonLabel, IonInput, IonList, IonIcon, IonSelectOption, IonSelect, useIonAlert, IonCard, IonCardContent, IonCardHeader, IonCardTitle} from '@ionic/react';
import { playCircleOutline } from 'ionicons/icons';
import {  sendOriCollectorCommand } from '../data/api';
import { useWebSocketStore } from '../stores/zustang';


interface ConfigMessage {
  config: { id: string; value: string; description: string }[];
  system?: {
    network?: { hostname?: string; ip?: string };
    datetime?: string;
    timezone?: string;
  };
  [key: string]: any;
}

const JobsAdd = ({
  onDismiss,
  userId,
  accessToken,
  selectedOri
}: {
  onDismiss: (data?: string | null | undefined | number | object, role?: string) => void;
  userId: string;
  accessToken: string;
  selectedOri: string;
}) => {

  const config = useWebSocketStore((state) => state.config) as ConfigMessage;
  const updateJob = useWebSocketStore((state) => state.updateJob)
  const statuses = useWebSocketStore((state) => state.statuses)
  console.log("Statuses as a whole", statuses, selectedOri)
  const deviceStatus = selectedOri ? statuses[selectedOri] || {} : {}; 
  const [presentAlert] = useIonAlert();

  const numSamplesCollection = Array.from({ length: 90 }, (_, i) => (i + 1).toString());
  const [ori, setOri] = useState("");
  const [robot, setRobot] = useState("");
  const [farm, setFarm] = useState("");
  const [username, setUsername] = useState("");
  const [numsamples, setNumsamples] = useState(0);
  const [detectPositionZero, setDetectPositionZero] = useState(false);

  const getConfigValue = (id: string): string | undefined => {
    if (!Array.isArray(config?.config)) {
      console.warn("❌ config.config is not an array", config?.config);
      return undefined;
    }
    return config.config.find((c) => c.id === id)?.value;
  };
  
  const getFormData = () => {
    return {
      ori,
      robot,
      farm,
      username,
      numsamples: String(numsamples), // just to be safe,
      detectpositionzero: detectPositionZero ? 1 : 0,
    };
  };

  const onAdd = async () => {
    const dt = new Date()
    const data = getFormData()
    const newid = dt.getTime() 
    const job = {
      id_job: newid,
      date_created: newid,
      oricollector: data.ori,
      robot: data.robot,
      id_user: 1,
      user: data.username,
      sample_tray: data.numsamples,
      farm: data.farm,
      status: 'creating'
    }
    
    sendOriCollectorCommand(userId, selectedOri, "create_job", job, accessToken).then(result => {
      if (result.message) {
        // ⚠️ Aquí debes filtrar los jobs y volver a setearlos
        if (selectedOri) {
          updateJob({selectedOri, job});
        }
      } else {
        presentAlert({
          header: 'Fallo',
          message: 'No se ha podido eliminar el proceso.',
          buttons: ['OK'],
        });
      }
      onDismiss(getFormData(), 'confirm')
    }).catch(error => {
      presentAlert({
        header: 'Fallo',
        message: 'No se ha podido crear el proceso.',
        buttons: ['OK'],
      })
    })
  }

  useEffect(() => {
    if (!config?.config) return; // 🔥 Check the internal array instead of whole config
    //if (!deviceStatus) return; 
    console.log("Device Status", deviceStatus)
    setOri(getConfigValue("ori") || "");
    setRobot(getConfigValue("robot") || "");
    setFarm(getConfigValue("farm") || "");
    setUsername(getConfigValue("username") || "");
    setNumsamples(parseInt(getConfigValue("numsamples") || "0", 10));
    setDetectPositionZero(getConfigValue("detectpositionzero") === "1");

  }, [config, deviceStatus])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
              Cancelar
            </IonButton>
          </IonButtons>
          <IonTitle>Crear proceso</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
        <IonItem>
              <IonLabel position="stacked">Farm Name</IonLabel>
              <IonInput placeholder="" value={farm} onIonChange={(e) => setFarm(e.detail.value ?? "")}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">OriCollector</IonLabel>
            <IonInput placeholder="Ref. de OriCollector" value={ori} onIonChange={(e) => setOri(e.detail.value ?? "")}/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Robot</IonLabel>
            <IonInput placeholder="Ref. del robot" value={robot} onIonChange={(e) => setRobot(e.detail.value ?? "")}/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Usuario</IonLabel>
            <IonInput placeholder="Nombre del usuario" value={username} onIonChange={(e) => setUsername(e.detail.value ?? "")}/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Número de muestras</IonLabel>
            <IonSelect onIonChange={(e) => setNumsamples(parseInt((e.detail.value) || "0", 10))} placeholder="" cancelText="Cancelar" value={numsamples.toString()}>
              {numSamplesCollection.map((sample: string) => (
                <IonSelectOption key={sample} value={sample}>{sample}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonList>

        {(getConfigValue("detectpositionzero") === "0" || (getConfigValue("detectpositionzero") === "1" && deviceStatus.is_position_zero === true)) ? (          
          <IonButton size="large" fill="solid" color="success" expand="block" className="ion-margin-top" onClick={() => onAdd()}>
            Iniciar proceso
            <IonIcon slot="end" ios={playCircleOutline} md={playCircleOutline}></IonIcon>
          </IonButton>
        ) : (
          <IonCard color="warning" className="ion-margin-top">
            <IonCardHeader style={{paddingBottom: '5px'}}>
              <IonCardTitle>Esperando a detectar la posición cero...</IonCardTitle>
              
            </IonCardHeader>

            <IonCardContent>
              Situa la bandeja de muestras de OriCollector en la posición cero.
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default JobsAdd;
