let APIEndpoint = "https://b00wydxve1.execute-api.eu-central-1.amazonaws.com/prod";

const fetchAPI = async (path, method = 'GET', data = {}, token = null) => {
    const headers = {
      'Content-Type': 'application/json',
    };
  
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
    const params = {
      headers,
      method,
    };
  
    if (method !== 'GET' && method !== 'HEAD' && data) {
      params.body = JSON.stringify(data);
    }
  
    const url = `${APIEndpoint}${path}`;
  
    return fetch(url, params)
      .then(response => {
        if (response.ok) return response.json();
        return response.json().then(err => {
          let errors = [];
          Object.keys(err).forEach(key => errors.push(`${key}: ${err[key]}`));
          return Promise.reject(new Error(errors));
        });
      })
      .catch(error => Promise.reject(new Error(error.message)));
  };
  
// EXAMPLE: Call OriCollectors
export const getUserOriCollectors = async (user_id, accessToken) => {
    return await fetchAPI(`/get-user-oricollectors-statuses`, 'POST', { user_id }, accessToken);
};

export const getWSEndpoint = () => APIEndpoint.replace('https:','http:').replace('http:','ws:') + '/ws';

export const sendOriCollectorCommand = async (user_id, device_id, command, params = {}, accessToken) => {
    const data = { user_id, device_id, command, params };
    return await fetchAPI(`/send_command`, 'POST', data, accessToken);
};
