// ✅ CSV Handler & Zustand integration

import { create } from 'zustand';

interface WebSocketStoreState {
  statuses: Record<string, any>;
  jobs: any;
  config: any;
  csvFiles: Record<string, { job_id: number; csv: string }>;
  notifications: any[];
  jobItems: Record<string, any>;
  scanResults: Record<string, any>;
  heartbeat: Record<string, any>;

  updateStatus: (deviceId: string, status: any) => void;
  updateJob: (jobData: any) => void;
  updateConfig: (config: any) => void;
  updateCSV: (hostname: string, job_id: number, csv: string) => void;
  addNotification: (notification: any) => void;
  updateJobItems: (deviceId: string, items: any) => void;
  updateHeartbeat: (deviceId: string, items: any) => void;
  updateScanResult: (deviceId: string, scanData: any) => void;
  removeJobItem: (deviceId: string, jobId: number) => void;
  clearCSV: (hostname: string) => void;
  resetStore: () => void;
}

export const useWebSocketStore = create<WebSocketStoreState>((set) => ({
  statuses: {},
  jobs: {},
  config: null,
  csvFiles: {},
  notifications: [],
  jobItems: {},
  scanResults: {},
  heartbeat: {},

  updateStatus: (deviceId, status) =>
    set((state) => ({ statuses: { ...state.statuses, [deviceId]: status } })),

  updateJob: (jobData) => set(() => ({ jobs: jobData })),

  resetStore: () =>
    set(() => ({
      statuses: {},
      jobs: {},
      config: null,
      csvFiles: {},
      notifications: [],
      jobItems: {},
      scanResults: {},
      heartbeat: {},
    })),

  updateConfig: (newConfig) =>
    set((state) => ({
      config: {
        ...state.config,
        ...newConfig,
        config: Array.isArray(newConfig.config) ? newConfig.config : state.config?.config ?? []
      }
    })),

  updateCSV: (hostname: string, job_id: number, csv: string) =>
    set((state) => ({
      csvFiles: {
        ...state.csvFiles,
        [hostname]: { job_id, csv },
      },
    })),
    
  clearCSV: (hostname: string) =>
    set((state) => {
      const updated = { ...state.csvFiles };
      delete updated[hostname];
      return { csvFiles: updated };
    }),
      
  addNotification: (notification) =>
    set((state) => ({
      notifications: [...state.notifications, notification],
    })),

  updateJobItems: (deviceId, items) =>
    set((state) => ({ jobItems: { ...state.jobItems, [deviceId]: items } })),

  updateHeartbeat: (deviceId, items) =>
    set((state) => ({
      heartbeat: { ...state.heartbeat, [deviceId]: items },
    })),

  removeJobItem: (deviceId, jobId) =>
    set((state) => {
      const updated = state.jobItems[deviceId]?.filter((job: any) => job.id_job !== jobId) ?? [];
      return {
        jobItems: {
          ...state.jobItems,
          [deviceId]: updated,
        },
      };
    }),

  updateScanResult: (deviceId, scanData) =>
    set((state) => ({
      scanResults: { ...state.scanResults, [deviceId]: scanData },
    })),
}));
