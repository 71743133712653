import React, { createContext, useContext, ReactNode } from 'react';

interface GlobalUserContextProps {
  userId: string | null;
  accessToken: string | null;
  selectedOri: string | null;
}

export const GlobalUserContext = createContext<GlobalUserContextProps>({
  userId: null,
  accessToken: null,
  selectedOri: null,
});

interface GlobalUserProviderProps extends GlobalUserContextProps {
  children: ReactNode;
}

export const GlobalUserProvider: React.FC<GlobalUserProviderProps> = ({ userId, accessToken, selectedOri, children }) => (
  <GlobalUserContext.Provider value={{ userId, accessToken, selectedOri }}>
    {children}
  </GlobalUserContext.Provider>
);

export const useGlobalUser = () => useContext(GlobalUserContext);
