import { createContext } from "react";

interface SelectedOriContextProps {
  selectedOri: string | null;
  setSelectedOri: (oriId: string) => void;
}

export const SelectedOriContext = createContext<SelectedOriContextProps>({
  selectedOri: null,
  setSelectedOri: () => {},
});
