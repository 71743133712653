import React from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import App from "./App";


const cognitoAuthConfig = {
  authority: "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3ui4PcA8s",
  client_id: "3489j27uvv4l9fr1mbjp6baddu",
  redirect_uri: "http://localhost:3000/hostname/select-hostname",
  response_type: "code",
  scope: "email openid phone",
};

const container = document.getElementById("root");
const root = createRoot(container!);

// ✅ Wrap `AuthProvider` around the App
root.render(
  <AuthProvider {...cognitoAuthConfig}>
    <App />
  </AuthProvider>
);



