import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/react';

const Merge: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Merge</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent >
        <p>Merge functionality currently being developed. Will be available ASAP</p>
      </IonContent>
    </IonPage>
  );
};

export default Merge;
