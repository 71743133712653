import {
  IonButtons, IonContent, IonHeader, IonButton, IonPage, IonTitle, IonToolbar,
  IonSelect, IonSelectOption, IonList, IonListHeader, IonItem, IonLabel,
  IonDatetime, IonModal, IonDatetimeButton, useIonAlert
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useWebSocketStore } from '../stores/zustang';

import { timeZones } from '../types/Timezone';
import { sendOriCollectorCommand } from '../data/api';

import './ConfigMain.css';

interface ConfigSystemProps {
  onDismiss: (data?: string | null | undefined | number | object, role?: string) => void;
  userId: string;
  accessToken: string;
  selectedOri: string;

}

interface ConfigMessages {
  config: { id: string; value: string; description: string }[];
  system?: {
    network?: { hostname?: string; ip?: string };
    datetime?: string;
    timezone?: string;
  };
  [key: string]: any;
}

const ConfigSystem: React.FC<ConfigSystemProps> = ({  onDismiss, userId, accessToken, selectedOri}) => {
  const config = useWebSocketStore((state) => state.config) as ConfigMessages;
  
  const [oriDateTime, setOriDateTime] = useState<string | undefined | null>(undefined);
  const [oriTimezone, setOriTimezone] = useState<string | undefined>(undefined);

  const [presentAlert] = useIonAlert();

  const timezoneAlertOptions = {
    header: 'Zona horaria'
  };

  const getFormData = () => {
    return {
      timezone: oriTimezone,
      datetime: oriDateTime
    };
  };

  const onSave = async () => {
    const config = getFormData();

    sendOriCollectorCommand(userId, selectedOri, "update_config_system", config, accessToken).then(result => {
      if (result.message) {
        presentAlert({
          header: 'Guardado',
          message: 'Se han guardado los ajustes correctamente.',
          buttons: ['OK'],
        });
      } else {
        presentAlert({
          header: 'Fallo',
          message: 'No se ha podido guardar los ajustes.',
          buttons: ['OK'],
        });
      }
    }).catch(() => {
      presentAlert({
        header: 'Fallo',
        message: 'No se ha podido guardar los ajustes.',
        buttons: ['OK'],
      });
    });
  };

  useEffect(() => {
    if (!config || typeof config !== 'object') return;
  
    const datetime = config.system?.datetime || "";
    const timezone = config.system?.timezone || "";
  
    setOriDateTime(datetime);
    setOriTimezone(timezone);
  }, [config]);

  return (
    <IonPage className="bg-white">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => onDismiss(null, 'cancel')}>Cancelar</IonButton>
          </IonButtons>
          <IonTitle>Ajustes de sistema</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onSave}>Guardar</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent >
        <IonList inset={true}>
          <IonListHeader>
            <IonLabel>Fecha y hora</IonLabel>
          </IonListHeader>

          <IonItem>
            <IonLabel>Fecha y hora</IonLabel>
            <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                id="datetime"
                locale="es-ES"
                firstDayOfWeek={1}
                value={oriDateTime ?? ""}
                onIonChange={(e) => setOriDateTime(e.detail.value as string | null | undefined)}
              />
            </IonModal>
          </IonItem>

          <IonItem>
            <IonLabel>
              <h2>Zona horaria</h2>
              <p><small>El cambio de zona horaria requiere reiniciar OriCollector.</small></p>
            </IonLabel>
            <IonSelect
              interfaceOptions={timezoneAlertOptions}
              onIonChange={(e) => setOriTimezone(e.detail.value)}
              placeholder=""
              cancelText="Cancelar"
              value={oriTimezone}
            >
              {(Array.isArray(timeZones) ? timeZones : []).sort().map((tz) => (
                <IonSelectOption key={tz} value={tz}>
                  {tz}
                </IonSelectOption>
              ))}
            </IonSelect>

          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ConfigSystem;
