import { IonPage, IonNav } from '@ionic/react';
import ConfigMain from './ConfigMain';

const Config: React.FC = () => {
  // ✅ Read the oriId from the URL
  return (
    <IonPage>
      <IonNav root={() => <ConfigMain />} />
    </IonPage>
  );
};

export default Config;
