import React, { useEffect, useContext, createContext, useRef } from "react";
import { useIonAlert, useIonToast } from '@ionic/react';
import { sendOriCollectorCommand} from "../data/api";
import { toast } from 'react-toastify';
const storeContext = createContext();



export function ProvideStore({ user_id, accessToken, selectedOri,children }) {
    const storeProvider = useProvideStore(user_id, accessToken, selectedOri);
    return <storeContext.Provider value={storeProvider}>{children}</storeContext.Provider>;
}


export const useStore = () => {
    return useContext(storeContext);
};

function useProvideStore(user_id, accessToken, selectedOri) {
    
    const storeReducer = (state, action) => {
		switch (action.type) {
            case 'SET_CONFIG':
                let cfg = {"system": action.system}
                action.config.forEach((item) => {
                    cfg[item.id] = item.value
                })
                cfg['detectpositionzero'] = Boolean(parseFloat(cfg['detectpositionzero']))
                cfg['showalerts'] = Boolean(parseFloat(cfg['showalerts']))
                console.log('SET_CONFIG', cfg)
                return {
                    ...state,
                    config: cfg
                };

            case 'SET_CONFIG_SYSTEM':
                console.log('SET_CONFIG_SYSTEM', action.config)
                return {
                    ...state,
                    config: {
                        ...state.config,
                        system: {
                            ...state.config.system,
                            datetime: action.config.datetime,
                            timezone: action.config.timezone
                        }
                    }
                };

            case 'SET_JOBS':                
                return {
                    ...state,
                    jobs: action.jobs
                };

		  	case 'ADD_JOB':
                return {
                    ...state,
                    jobs: [action.payload, ...state.jobs]
                };
	
		  	case 'REMOVE_JOB':
				return {
                    ...state,
                    jobs: state.jobs.filter((item) => item.id_job !== action.id_job)
                };

			case 'UPDATE_JOB':
				return state.jobs.map((item) => {
					if (item.id === action.id) {
						const updatedItem = {
							...item,
							...action.item
						};
						return updatedItem;
					}
	
					return item;
				});

            case 'LOAD_JOBS':
                fetchJobs()
                return {
                    ...state
                };

            case 'SET_CONTROL_STATUS':
                console.log('SET_CONTROL_STATUS', action.control)
                return {
                    ...state,
                    control: action.control
                }

            case 'SET_CONTROL_POSITION_ZERO':
                return {
                    ...state,
                    control: {
                        ...state.control, 
                        is_position_zero: action.is_position_zero
                    }
                }

                case 'SET_CONTROL_CAMERA_CONNECTION':
                    return {
                        ...state,
                        control: {
                            ...state.control, 
                            camera_connected: action.camera_connected
                        }
                    }

            case 'SET_WS_STATUS':
                return {
                    ...state,
                    ws: {
                        status: action.status
                    }
                }

		  	default:
                return state
				//throw new Error();
		}
	};
    
    const statusInterval = useRef();

    const [presentAlert] = useIonAlert();
    
	const initialStore = {
        ws: {
            status: 'disconnected'
        },
        control: {
            status: 0,            
            status_name: 'STOP',
            status_desc: 'OriCollecor está parado',
            id_job: null,
            position: 0,
            total_positions: 0,
            cycle: 0,
            total_cycles: 0,
            progress: 0,
            is_position_zero: false,
            camera_connected: false,
            show_alerts: true
        },
        config: {
            system: {
                datetime: '',
                network: {
                    hostname: '',
                    ip: ''
                }
            }
        },
        jobs: []
    }
	const [store, dispatchStore] = React.useReducer(
		storeReducer,
		initialStore
	);

    const fetchConfig = async () => {
        try {
          const response = await sendOriCollectorCommand(
            user_id,
            selectedOri,
            "get_config",
            {},
            accessToken
          );
      
          console.log("✅ Command ent successfully:", response);
          // We don't dispatch here — actual data will arrive via WebSocket
      
        } catch (error) {
          presentAlert({
            header: 'Fallo',
            message: 'No se ha podido enviar la orden al OriCollector.',
            buttons: ['OK'],
          });
        }
      };

    const fetchJobs = async () => {
        sendOriCollectorCommand(user_id, selectedOri, "get_jobs", {}, accessToken).then(json => {
            dispatchStore({
                type: 'SET_JOBS',
                jobs: json.jobs
            })
        }).catch(error => {
            presentAlert({
                header: 'Fallo',
                message: 'No se ha podido cargar los procesos de OriCollector.',
                buttons: ['OK'],
            })
        })
    };

    const updateControlStatus = async () => {
        sendOriCollectorCommand(user_id, selectedOri, "hostname_status", {}, accessToken).then(json => {
            dispatchStore({
                type: 'SET_CONTROL_STATUS',
                control: json
            })
        }).catch(error => {
            //alert('Error al conectar con el servidor.')
            presentAlert({
                header: 'Fallo',
                message: 'No se ha podido obtener el estado de OriCollector.',
                buttons: ['OK'],
            })
        })
    }

    const handleWebsockets = async (event) => {
        if (!(event !== null && typeof event === 'object')) return false;
        const msg = JSON.parse(event.data)
        console.log('WS', msg)
        switch (msg.type) {
            case 'event':
                switch (msg.event) {
                    case 'disconnected':
                        presentAlert({
                            header: 'Desconectado',
                            message: 'Se ha desconectado de OriCollector.',
                            buttons: ['OK'],
                        })
                        break;
                    
                    case 'update_status':
                        //updateControlStatus()
                        break;

                    case 'position_zero_detected':
                        dispatchStore({
                            type: 'SET_CONTROL_POSITION_ZERO',
                            is_position_zero: true
                        })
                        break;
                    
                    case 'position_zero_away':
                        dispatchStore({
                            type: 'SET_CONTROL_POSITION_ZERO',
                            is_position_zero: false
                        })
                        break;

                    case 'camera_connected':
                        dispatchStore({
                            type: 'SET_CONTROL_CAMERA_CONNECTION',
                            camera_connected: true
                        })
                        break;

                    case 'camera_not_connected':
                        dispatchStore({
                            type: 'SET_CONTROL_CAMERA_CONNECTION',
                            camera_connected: false
                        })
                        break;

                    default:
                        break;
                }
                break;

            case 'alert':
                switch (msg.message) {
                    case 'preso_detected_but_not_started':
                        presentAlert({
                            header: 'Presostato detectado',
                            message: 'Se ha detectado el presostato pero el proceso no está iniciado.',
                            buttons: ['OK'],
                        })
                        break;
                    case 'tag_is_empty':
                        toast.warn(`El tag escaneado está vacío. Comprobar posición ${msg.params.position}.`, {
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });                        
                        break;
                    case 'tag_already_detected':
                        toast.warn(`Se ha escaneado el mismo tag. Verificar posible atasco. Posición ${msg.params.position}.`, {
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });                        
                        break;
                    
                    case 'camera_not_connected':
                        presentAlert( {
                            header: `Camara desconectada`,
                            message: `Conectala y reanuda el proceso`,
                            buttons:['OK'],
                        });                        
                        break;
                    default:
                        presentAlert({
                            header: 'Aviso',
                            message: msg.message,
                            buttons: ['OK'],
                        })
                        break;
                }
                break;
            default:
                break;
        }
    } 

    useEffect(() => {
        fetchConfig();
        fetchJobs();
        //updateControlStatus();
    }, []);
    
    return [
		store,
        dispatchStore,
        //updateControlStatus,
        handleWebsockets
	];
}




