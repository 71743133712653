import { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonNavLink, IonItemSliding, IonItemOptions, IonItemOption, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import { IonItem, IonLabel, IonList, IonNote, useIonModal, useIonAlert } from '@ionic/react';
import './Jobs.css';
import JobsDetail from './JobsDetail';
import JobsAdd from './JobsAdd';
import { OverlayEventDetail } from '@ionic/core/components';
import { JobItem } from '../types/Jobs';
import moment from 'moment/moment'
import "moment/locale/es";
import { sendOriCollectorCommand } from '../data/api';
import { useEffect, useMemo  } from "react";
import { useGlobalUser } from "../GlobalStoreContext";
import { useWebSocketStore } from '../stores/zustang';


const JobsList: React.FC  = ({  }) => {
  const { userId, accessToken, selectedOri } = useGlobalUser();
  const  jobItems  = useWebSocketStore((state) => state.jobItems);
  const   removeJobItem  = useWebSocketStore((state) => state.removeJobItem);
  const   statuses  = useWebSocketStore((state) => state.statuses);
  const items: JobItem[] = useMemo(() => {
  return jobItems[selectedOri ?? ''] ?? [];
}, [jobItems, selectedOri]);
  const deviceStatus = selectedOri ? statuses[selectedOri] || {} : {}; 

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    fetchJobs();
    event.detail.complete();
  }

  const [present, dismiss] = useIonModal(JobsAdd, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    userId,
    accessToken,
    selectedOri

  });

  const showAddNew = () => {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'confirm') {
        }
      },
    });
  }

  const [presentAlert] = useIonAlert()

  const requestDelete = (item: JobItem) => {
    presentAlert({
      header: '¿Está seguro/a que desea eliminar el proceso?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
          },
        },
        {
          text: 'Sí, eliminar',
          role: 'confirm',
          handler: () => {
            doDeleteJob(item.id_job);
          },
        },
      ]
    })
  }

  const doDeleteJob = (id_job: number) => {
    sendOriCollectorCommand(userId, selectedOri, "delete_job", id_job, accessToken).then(result => {
      if (result.message) {
        // ⚠️ Aquí debes filtrar los jobs y volver a setearlos
        if (selectedOri) {
          removeJobItem(selectedOri, id_job);
        }

      } else {
        presentAlert({
          header: 'Fallo',
          message: 'No se ha podido eliminar el proceso.',
          buttons: ['OK'],
        });
      }
    }).catch(() => {
      presentAlert({
        header: 'Fallo',
        message: 'No se ha podido eliminar el proceso.',
        buttons: ['OK'],
      });
    });
  };
  
  
  const fetchJobs = async () => {
    if (!selectedOri) return;
    try {
      await sendOriCollectorCommand(userId, selectedOri, "get_jobs", {}, accessToken)
    } catch (error) {
      presentAlert({
        header: 'Fallo',
        message: 'No se ha podido cargar los procesos de OriCollector.',
        buttons: ['OK'],
      });
    }
  };

  useEffect(() => {
  }, [jobItems, selectedOri, deviceStatus]);
  

  return (
    <>
    
      <IonHeader>
        <IonToolbar>
          <IonTitle>Procesos</IonTitle>
          {deviceStatus.status_name==='STOP' && (
          <IonButtons collapse={false} slot="end">
            <IonButton onClick={() => showAddNew()}>Nuevo</IonButton>
          </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent >
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Procesos</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonList lines="full" inset={true}>
          {items?.map((item: JobItem) => (
            <IonItemSliding key={item.id_job} disabled={(deviceStatus.status_name === 'PAUSE' || deviceStatus.status_name === 'START') && item.id_job === deviceStatus.id_job}>
              <JobsListItem item={item} ondelete={() => requestDelete(item)} />
            </IonItemSliding>
          ))}
        </IonList>
      </IonContent>
    </>
  );
};

export default JobsList;

type JobListItemProps = {
  item: JobItem,
  ondelete: Function
}
export const JobsListItem = ({item, ondelete}:JobListItemProps) => {  
  const { selectedOri } = useGlobalUser();
  const { statuses } = useWebSocketStore();

  const deviceStatus = selectedOri ? statuses[selectedOri] || {} : {}; 
  let itemDate = moment(item.date_created)
  return (
    <>
      <IonNavLink routerDirection="forward" component={() => <JobsDetail item={item} />}>
          <IonItem detail={true} button>
          <IonLabel>
              <h2>{itemDate.format("DD/MM/yyyy HH:mm")}</h2>
              <p>{item.oricollector} - {item.robot} - {item.user} - {item.sample_tray}</p>
          </IonLabel>
          {(deviceStatus.status === 'START' || item.id_job === deviceStatus.id_job) && (
              <IonNote slot="end">
                {deviceStatus.status_name === 'START'
                  ? 'En ejecución'
                  : deviceStatus.status_name === 'PAUSE'
                  ? 'En pausa'
                  : ''}
              </IonNote>
          )}
          </IonItem>
      </IonNavLink>
      {item.status !== 'running' && (
        <IonItemOptions>
          <IonItemOption color="danger" onClick={() => ondelete(item)}>Eliminar</IonItemOption>
        </IonItemOptions>
      )}
    </>
  )
}
