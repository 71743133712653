import { IonPage, IonNav } from '@ionic/react';
import JobsList from './JobsList';

const Jobs: React.FC = ({  }) => {

  return (
    <IonPage>
      {/* Pass the APIHostname prop to JobsList */}
      <IonNav root={() => <JobsList/>} />
    </IonPage>
  );
};

export default Jobs;
