export interface IControlStatus {
    name: string,
    desc: string
}
export interface IControlStatuses {
    STOP: IControlStatus,
    START: IControlStatus
}
export const ControlStatus = {
    'STOP': {
        name: 'Parado ⏹',
        desc: 'OriCollector está parado.'
    },
    'START': {
        name: 'En ejecución ▶️',
        desc: 'OriCollector está trabajando.<br>Iniciado a las {0}.'
    },
    'PAUSE': {
        name: 'En pausa <span class="blink-me">⏸</span>',
        desc: 'OriCollector está en pausa.<br>Haga clic en "Continuar" cuando esté listo.'
    },
    'FINISH': {
        name: 'Finalizado 🎉',
        desc: 'OriCollector ha finalizado.<br>Puede iniciar un nuevo proceso.'
    }
}