
import { useState, useEffect, useCallback, useRef } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { toast, ToastOptions, ToastContent, Id } from 'react-toastify';
import { Hub } from 'aws-amplify/utils';
import { useWebSocketStore } from "./stores/zustang"; // adjust the path if needed

// Singleton Instances:
let socketInstance: WebSocket | null = null;
let reconnectTimeoutInstance: ReturnType<typeof setTimeout> | null = null;
let activeToastId: Id | null = null;
let connectingRef = false; // NEW! to block multiple connections

export const useWebSocketWithRetry = (maxRetries = 3, retryDelay = 3000) => {
  const [retryCount, setRetryCount] = useState(0);
  const [wsConnected, setWsConnected] = useState(false);
  const [shouldConnect, setShouldConnect] = useState(true);
  const disconnectingRef = useRef(false);
  const [lastMessage] = useState<any>(null);
  const [heartbeatTimestamps] = useState<Record<string, number>>({});
  const [heartbeatStatus] = useState<Record<string, string>>({});
  const [connecting, setConnecting] = useState(false);
  const TOAST_ID = 'ws-status-toast';

  const showToast = (content: ToastContent, options: ToastOptions = {}) => {
    if (!toast.isActive(TOAST_ID)) {
      const id = toast.info(content, { toastId: TOAST_ID, ...options });
      activeToastId = id;
    } else {
      toast.update(TOAST_ID, {
        render: content,
        ...options,
      });
    }
  };

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const clearToast = () => {
    try {
      if (activeToastId !== null && toast.isActive(TOAST_ID)) {
        toast.dismiss(TOAST_ID);
        activeToastId = null;
      }
    } catch (err) {
      console.warn("Tried to dismiss toast but failed.", err);
    }
  };

  const cleanupWebSocket = useCallback(() => {
    console.log('🧹 Cleaning WebSocket...');
    if (socketInstance) {
      socketInstance.onclose = null;
      socketInstance.onerror = null;
      socketInstance.onopen = null;
      socketInstance.onmessage = null;
      socketInstance.close();
      socketInstance = null;
    }
    if (reconnectTimeoutInstance) {
      clearTimeout(reconnectTimeoutInstance);
      reconnectTimeoutInstance = null;
    }
    connectingRef = false;
    setConnecting(false);
    setRetryCount(0);
    setWsConnected(false);
    clearToast();
  }, []);

  const connectWebSocket = useCallback(async () => {
    if (!shouldConnect) {
      console.log('⏭️ Skipping WS connection: signed out');
      return;
    }

    if (connectingRef) {
      console.log('⚠️ Already trying to connect, skipping...');
      return;
    }

    if (socketInstance && (socketInstance.readyState === WebSocket.OPEN || socketInstance.readyState === WebSocket.CONNECTING)) {
      console.log('⚠️ WS already connected/connecting');
      return;
    }

    cleanupWebSocket();
    connectingRef = true;
    setConnecting(true);

    try {
      const session = await fetchAuthSession();
      if (!session.tokens) {
        console.warn('🚫 No valid session. Skipping WS connection.');
        connectingRef = false;
        setConnecting(false);
        return;
      }

      const user = await getCurrentUser();
      const user_id = user.userId;

      let deviceId = localStorage.getItem('deviceId');
      if (!deviceId) {
        deviceId = generateUUID();
        localStorage.setItem('deviceId', deviceId);
      }

      const wsUrl = `wss://9ef3kstvp3.execute-api.eu-central-1.amazonaws.com/production?user_id=${user_id}&deviceId=${deviceId}`;
      console.log(`🔌 Connecting WebSocket: ${wsUrl}`);
      socketInstance = new WebSocket(wsUrl);

      socketInstance.onopen = () => {
        console.log('✅ WebSocket Connected!');
        setConnecting(false);
        connectingRef = false;
        clearToast();
        toast.success('✅ Connected to server', { toastId: TOAST_ID, autoClose: 3000 });
        setWsConnected(true);
        setRetryCount(0);
      };

      socketInstance.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          const { message, hostname, ...rest } = data.status;
          console.log("message", data)
          const store = useWebSocketStore.getState(); // Access Zustand store methods
      
          switch (message) {
            case "control_status":
              store.updateStatus(hostname, { ...rest, type: "status" });
              break;
            case "heartbeat":
              store.updateHeartbeat(hostname, {  ...rest });
              break;
            case "config":
              store.updateConfig({ hostname, ...rest });
              break;
            case "jobs_created":
              store.updateJob({ hostname, ...rest });
              break;
            case "jobs_current_id":
              store.updateJob({ hostname, ...rest }); // You might want a separate handler
              break;
            case "email_csv":
              // Optional: You can store in a notifications state
              break;
            case "csv_file":
              const { csv_data, job_id } = data.status;
              store.updateCSV(hostname, job_id, csv_data);
              break;
            case "position_scan":
              store.updateStatus(hostname, { ...rest, type: "position_scan" });
              break;
            case "jobs": {
              const { device_id, status } = data;
              const jobs = status?.jobs;
            
              console.log("📦 Actualización:", jobs, device_id);
            
              if (device_id && Array.isArray(jobs)) {
                store.updateJobItems(device_id, jobs);
              }
              break;
            }              
            case "jobs_id":
              store.updateJob({ hostname, ...rest });
              break;
            case "jobs_created":
              store.updateJob({ hostname, ...rest });
              break;
            case "jobs_id_job_items_post":
            case "jobs_id_job_items_get":
              store.updateJob({ hostname, ...rest });
              break;
            case "delete_jobs": 
              break;
            case "config_system":
              store.updateConfig({ hostname, ...rest });
              break;
            default:
              console.warn("⚠️ Unknown WS message type:", message, rest);
              break;
          }
        } catch (err) {
          console.error("❌ Failed to parse WS message", err);
        }
    };
      

      const handleDisconnect = () => {
        console.warn('❌ WS Disconnected');
        setWsConnected(false);
        setConnecting(false);
        connectingRef = false;

        if (disconnectingRef.current || !shouldConnect) {
          console.log('Silent disconnect → No reconnect');
          return;
        }

        if (retryCount < maxRetries) {
          const nextAttempt = retryCount + 1;
          showToast(`🔄 Connection lost. Retrying ${nextAttempt}/${maxRetries}...`);
          setRetryCount(nextAttempt);
          reconnectTimeoutInstance = setTimeout(connectWebSocket, retryDelay);
        } else {
          showToast(
            <div>❌ Failed to reconnect.
              <button onClick={() => {
                clearToast();
                setRetryCount(0);
                connectWebSocket();
              }}>Reconnect</button>
            </div>,
            { autoClose: false }
          );
        }
      };

      socketInstance.onclose = handleDisconnect;
      socketInstance.onerror = (error) => {
        console.error('WS Error:', error);
        socketInstance?.close();
      };

    } catch (err) {
      console.warn('❌ Auth failed:', err);
      setConnecting(false);
      connectingRef = false;
    }
  }, [shouldConnect, retryCount, cleanupWebSocket]);

  const reconnectWebSocket = () => {
    console.log("🔄 Manual WebSocket reconnect...");
    setRetryCount(0);
    connectWebSocket();
  };

  useEffect(() => {
    connectWebSocket();
    return () => {
      cleanupWebSocket();
    };
  }, [connectWebSocket, cleanupWebSocket]);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signedOut') {
        console.log('🚪 User signed out → Stop WS reconnects');
        disconnectingRef.current = true;
        setShouldConnect(false);
        cleanupWebSocket();
      }
      if (payload.event === 'signedIn') {
        console.log('✅ User signed in → Allow WS connect');
        disconnectingRef.current = false;
        setShouldConnect(true);
        connectWebSocket();
      }
    });

    return () => unsubscribe();
  }, [connectWebSocket, cleanupWebSocket]);

  return {
    wsConnected,
    lastMessage,
    heartbeatTimestamps,
    heartbeatStatus, // ✅ ADD THIS LINE
    reconnectWebSocket,
    connecting
  };
};
