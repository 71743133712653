import { IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonModal, useIonAlert, IonButtons, IonImg } from '@ionic/react';
import { IonButton, IonRefresher, IonRefresherContent, RefresherEventDetail, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonProgressBar } from '@ionic/react';
import './Control.css';
import { IControlStatuses, ControlStatus } from '../types/ControlStatus';
import { useEffect, useState, useMemo} from 'react';
import JobsAdd from './JobsAdd';
import { OverlayEventDetail } from '@ionic/core/components';
import { pauseCircleOutline, playCircleOutline, stopCircleOutline } from 'ionicons/icons';
import { sendOriCollectorCommand } from '../data/api';
import moment from 'moment/moment'
import "moment/locale/es";
import { useWebSocketStore } from '../stores/zustang';
import { useGlobalUser } from "../GlobalStoreContext";
import { JobItem } from '../types/Jobs';
interface ControlProps {
  APIHostname: string;
}

const Control: React.FC = () => {
  const { statuses } = useWebSocketStore();
  const { jobItems } = useWebSocketStore();
  const { userId, accessToken, selectedOri } = useGlobalUser();
  const deviceStatus = selectedOri ? statuses[selectedOri] || {} : {}; 
  const items: JobItem[] = useMemo(() => {
    return jobItems[selectedOri ?? ''] ?? [];
  }, [jobItems, selectedOri]);

  const [desc, setDesc] = useState<string>("");

  const [isLoading] = useState(false)

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    event.detail.complete();
  }
  
  const [present, dismiss] = useIonModal(JobsAdd, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    userId,
    accessToken,
    selectedOri
  });


  const [presentAlert] = useIonAlert();

  const showAddNew = () => {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'confirm') {
        }
      },
    });
  }

  const doSetControlStatus = async (status: string) => {
    sendOriCollectorCommand(userId, selectedOri, status + "_job", {}, accessToken).then(json => {
    }).catch(error => {
      presentAlert({
        header: 'Fallo',
        message: 'No se ha podido cambiar el estado.',
        buttons: ['OK'],
      })
    })
  }

  const onStopButton = () => {
    presentAlert({
      header: '¿Está seguro/a que desea parar el trabajo?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
          },
        },
        {
          text: 'Sí, parar',
          role: 'confirm',
          handler: () => {
            doSetControlStatus('stop');
          },
        },
      ]      
    })
  }

  const onPauseButton = () => {
    doSetControlStatus('pause')
  }

  const onContinueButton = () => {
    doSetControlStatus('start')
  }


  useEffect(() => {
  }, [selectedOri]);

  
  useEffect(() => {
    
    let msg : string = ControlStatus[deviceStatus.status_name as keyof IControlStatuses]?.desc
    if (deviceStatus.status_name === "START" && deviceStatus.id_job == items[0]?.id_job) {
      let dt = moment( items[0]?.date_created)
      const startedAt = dt.format("HH:mm:ss")
      msg = msg.replace("{0}", startedAt);
    }
    setDesc(msg); 
  }, [deviceStatus.status_name, jobItems])
  

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Control</IonTitle>
          {deviceStatus.status_name==='START' && (
          <IonButtons collapse={false

          } slot="end">
            
          </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Control</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonCard>
          {(deviceStatus.status_name==='START' || deviceStatus.status_name==='PAUSE') && (
            <div className="ori-image-wrapper">
                <img src="/assets/ori.svg" alt="" className={`ori-image ${(deviceStatus.status_name==='START') ? 'ori-image-spinning' : ''}`} />
            </div>
          )}
          {deviceStatus.status_name==='FINISH' && (
            <div className="ori-image-wrapper">
              <div className="icon icon-success svg"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">  
                  <g fill="none" stroke="#22AE73" stroke-width="2"> 
                    <circle cx="77" cy="77" r="72" style={{strokeDasharray:'480px, 480px', strokeDashoffset: '960px'}}></circle>
                    <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{strokeDasharray:'480px, 480px', strokeDashoffset: '960px'}}></circle>
                    <polyline className="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{strokeDasharray:'100px, 100px', strokeDashoffset: '200px'}} />   
                  </g> 
                </svg>
              </div>
            </div>
          )}
          <IonCardHeader>
            <IonCardSubtitle>Estado</IonCardSubtitle>
            <IonCardTitle dangerouslySetInnerHTML={{__html: ControlStatus[deviceStatus.status_name as keyof IControlStatuses]?.name}}></IonCardTitle>
          </IonCardHeader>
          
          <IonCardContent dangerouslySetInnerHTML={{__html: desc}}>
          </IonCardContent>          
          
        </IonCard>
          
        {(deviceStatus.status_name==='STOP' || deviceStatus.status_name==='FINISH') && (
        <div className="ion-padding">
          <div>
            <IonButton size="large" fill="solid" expand="block" className="ion-margin-top" onClick={() => showAddNew()}>
              Crear nuevo proceso
              <IonIcon slot="end" ios={playCircleOutline} md={playCircleOutline}></IonIcon>
            </IonButton>
          </div>
        </div>
        )}

        {(deviceStatus.status_name === 'START' || deviceStatus.status_name === 'PAUSE') && (
          <>
            <div className="control-wrapper">
              
              <div>
                Posición: <strong>{deviceStatus.position}</strong> / {deviceStatus.total_positions}
              </div>

              <div>
                Completado: <strong>{(deviceStatus.progress * 100).toFixed(0)}%</strong>
              </div>
            </div>
            
            <IonProgressBar value={deviceStatus.progress} className="ion-margin-top"></IonProgressBar>
            
            <div className="control-wrapper">
              <div className="">                            
                <IonButton size="large" fill="clear" color="danger" className="stop-icon" onClick={() => onStopButton()} disabled={isLoading}>
                  <IonIcon slot="start" ios={stopCircleOutline} md={stopCircleOutline}></IonIcon>
                  Parar
                </IonButton>
              </div>
              <div className="">
              {deviceStatus.status_name === 'START' ? (
                <IonButton size="large" fill="outline" className="pause-icon" onClick={() => onPauseButton()} disabled={isLoading}>
                  Pausa
                  <IonIcon slot="end" ios={pauseCircleOutline} md={pauseCircleOutline}></IonIcon>
                </IonButton>
              ) : (
                <IonButton size="large" fill="solid" className="pause-icon" onClick={() => onContinueButton()}>
                  Continuar
                  <IonIcon slot="end" ios={playCircleOutline} md={playCircleOutline}></IonIcon>
                </IonButton>
              )}
              </div>
            </div>
            
          </>
        )}
        {((deviceStatus.status_name==='START' || deviceStatus.status_name==='PAUSE') && deviceStatus.position > 0) && (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Control Picture</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
          {deviceStatus.photo ? (
            <img
              src={`data:image/jpeg;base64,${deviceStatus.photo}`} // Change to image/jpeg if needed
              alt="Control Picture"
              style={{ width: '100%' }}
            />
          ) : (
            <p>Loading picture...</p>
          )}
          </IonCardContent>
        </IonCard>
      )}
      </IonContent>
    </IonPage>
  );
};


export default Control;